




































import { MediaStatus } from '@/models/medias/mediaStatus';
import { Routes } from '@/router/routes';
import { mediaService } from '@/services/medias/media.service';
import { MediasActions, MediasGetters } from '@/store/modules/medias/enums';
import { MerchantConfigActions } from '@/store/modules/merchant-config/enums';
import { Component, Vue } from 'vue-property-decorator';
import CustomerDashboard from '@/views/customer/CustomerDashboard.vue';
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import HeaderBackground from '@/components/layout/HeaderBackground.vue';

@Component({
  components: {
    'ck-customer-dashboard': CustomerDashboard,
    'ck-footer': Footer,
    'ck-header': Header,
    'ck-header-background': HeaderBackground,
  },
})
export default class PreCustomerDashboard extends Vue {
  public get mediaStatus(): MediaStatus {
    return this.$store.getters[
      MediasGetters.GetMediaStatusToAssociate
    ] as MediaStatus;
  }

  public get hasCustomer(): boolean {
    const media = this.$store.getters[
      MediasGetters.GetMediaStatusToAssociate
    ] as MediaStatus;
    if (media == null) {
      return false;
    }
    return !!media.customerId;
  }

  public async mounted(): Promise<void> {
    if (!this.mediaStatus) {
      this.$router.push({ name: Routes.Customer });
    }
    if (this.hasCustomer) {
      this.showModal();
    } else {
      await this.associateMedia();
    }
  }

  public async associateMedia(): Promise<void> {
    this.$spinner.showSpinner();
    this.$bvModal.hide('modalQr');
    mediaService
      .associateMediaCustomer(this.mediaStatus.hash)
      .then(() => {
        this.$store.dispatch(MediasActions.SetMediaStatusToAssociate, null);
        this.$store.dispatch(MediasActions.GetMedias, true).then((medias) => {
          if (medias.length > 0) {
            this.$store.dispatch(
              MerchantConfigActions.GetMerchantConfig,
              this.$store.getters[MediasGetters.GetMerchantId],
            );
          }
          this.$router.push({ name: Routes.Customer });
        });
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public showModal(): void {
    //const modal = this.$refs['modal-qr'] as any;
    //modal.show();
    this.$bvModal.show('modalQr');
  }

  public async closeModal(): Promise<void> {
    //const modal = this.$refs['modal-qr'] as any;
    //modal.hide();
    this.$store.dispatch(MediasActions.SetMediaStatusToAssociate, null);
    this.$bvModal.hide('modalQr');
    await this.goToCustomer();
  }

  public async goToCustomer(): Promise<void> {
    this.$store.dispatch(MediasActions.SetMediaStatusToAssociate, null);
    this.$router.push({ name: Routes.Customer });
  }
}
